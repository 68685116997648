import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";

import LocalStorage from "../state/LocalStorageHelper";
import {
  createAccountAPICall,
  loginAPICall,
  resetPasswordAPICall,
  changePasswordAPICall,
  addSubscriptionAPICall,
  fetchSubscriptionAPICall,
  fetchStripeDashboardAPICall,
  cancelSubscriptionAPICall,
  pauseSubscriptionAPICall,
  resumeSubscriptionAPICall,
  currentUserAPICall,
  subscriptionDetailsAPICall,
  editUserAPICall,
  plankaAccessDetailsAPICall,
  plankaAccessDirectlyToCardAPICall,
} from "../api/UserService";
import { navigateToDashboard } from "../../../helpers/NavigationHelpers";

export const UserContext = createContext({
  currentUser: () => {},
  createUser: () => {},
  login: () => {},
  resetPassword: () => {},
  changePassword: () => {},
  addSubscription: () => {},
  fetchCurrentSubscription: () => {},
  fetchBillingPortalURL: () => {},
  cancelSubscription: () => {},
  resumeSubscription: () => {},
  pauseSubscription: () => {},
  fetchCurrentUser: () => {},
  navigateToDashboardIfPossible: () => {},
  fetchSubscriptionDetails: () => {},
  editUser: () => {},
  plankaAccessDetails: () => {},
  plankaAccessDirectlyToCard: () => {},
});

const UserContextProvider = ({ children, setLoading }) => {
  const userRef = useRef(null);

  const currentUser = () => {
    return LocalStorage.getCurrentUser();
  };

  const createUser = useCallback(
    async (email, name, password, onSuccess, onError) => {
      createAccountAPICall(
        email,
        name,
        password,
        function (response) {
          handleLoggedInUserResponse(response);
          onSuccess(response);
        },
        onError
      );
    }
  );

  const loginUser = useCallback(async (email, password, onSuccess, onError) => {
    loginAPICall(
      email,
      password,
      function (response) {
        handleLoggedInUserResponse(response);
        onSuccess(response);
      },
      onError
    );
  });

  const fetchCurrentUser = useCallback(async (onSuccess, onError) => {
    currentUserAPICall(function (response) {
      handleUserResponse(response);
      onSuccess(response);
    }, onError);
  });

  const resetPassword = useCallback(async (email, onSuccess, onError) => {
    resetPasswordAPICall(email, onSuccess, onError);
  });

  const changePassword = useCallback(
    async (token, password, onSuccess, onError) => {
      changePasswordAPICall(token, password, onSuccess, onError);
    }
  );

  const addSubscription = useCallback(
    async (productType, onSuccess, onError) => {
      addSubscriptionAPICall(productType, onSuccess, onError);
    }
  );

  const fetchCurrentSubscription = useCallback(async (onSuccess, onError) => {
    fetchSubscriptionAPICall(onSuccess, onError);
  });

  const fetchBillingPortalURL = useCallback(async (onSuccess, onError) => {
    fetchStripeDashboardAPICall(onSuccess, onError);
  });

  const cancelSubscription = useCallback(async (onSuccess, onError) => {
    cancelSubscriptionAPICall(onSuccess, onError);
  });

  const resumeSubscription = useCallback(async (onSuccess, onError) => {
    resumeSubscriptionAPICall(onSuccess, onError);
  });

  const pauseSubscription = useCallback(async (onSuccess, onError) => {
    pauseSubscriptionAPICall(onSuccess, onError);
  });

  const fetchSubscriptionDetails = useCallback(async (onSuccess, onError) => {
    subscriptionDetailsAPICall(onSuccess, onError);
  });

  const editUser = useCallback(async (name, email, onSuccess, onError) => {
    editUserAPICall(name, email, onSuccess, onError);
  });

  const plankaAccessDetails = useCallback(async (onSuccess, onError) => {
    plankaAccessDetailsAPICall(onSuccess, onError);
  });

  const plankaAccessDirectlyToCard = useCallback(
    async (cardId, onSuccess, onError) => {
      plankaAccessDirectlyToCardAPICall(cardId, onSuccess, onError);
    }
  );

  // This will redirect the user from the current page to dashboard if logged in
  const navigateToDashboardIfPossible = useCallback(async () => {
    setLoading(true);

    fetchCurrentUser(
      (response) => {
        setLoading(false);
        // User is authenticated go to dashboard
        navigateToDashboard();
      },
      () => {
        setLoading(false);
      }
    );
  });

  const handleLoggedInUserResponse = (response) => {
    handleUserResponse(response);
    LocalStorage.setAuthenthicationToken(response.data.access_token);
    LocalStorage.setRefreshToken(response.data.refresh_token);
  };

  const handleUserResponse = (response) => {
    userRef.current = response.data.user;
    LocalStorage.setCurrentUser(response.data.user);
  };

  return (
    <UserContext.Provider
      value={{
        currentUser,
        createUser,
        loginUser,
        resetPassword,
        changePassword,
        addSubscription,
        fetchCurrentSubscription,
        fetchBillingPortalURL,
        cancelSubscription,
        resumeSubscription,
        pauseSubscription,
        fetchCurrentUser,
        navigateToDashboardIfPossible,
        fetchSubscriptionDetails,
        editUser,
        plankaAccessDetails,
        plankaAccessDirectlyToCard,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
export default UserContextProvider;
