import { ClientNavBarButton } from "./NavigationBar";
import NavigationBar from "./NavigationBar";
import $ from "jquery";

function MobileMenuButton({ title, href }) {
  const onClick = () => {
    // Show hide menu container
    $("#mobile-menu-links-container").toggle();
    $("#home").toggle();
  };

  return (
    <div className="mobile-button-action-container" onClick={onClick}>
      <a className="mobile-button-action-text" href={href}>
        {title}
      </a>
    </div>
  );
}

export default function Menu({ showMenu, showLogout }) {
  return (
    <div id="mobile-menu-container">
      <div className="mobile-menu-bar-container">
        <NavigationBar showMenu={showMenu} showLogout={showLogout} />
      </div>
      <div id="mobile-menu-links-container">
        <div className="container">
          <MobileMenuButton title={"Blog"} href="/blog" />
          <MobileMenuButton title={"Recent work"} href="/#our-work" />
          <MobileMenuButton title={"Pricing"} href="/#plans" />
          <MobileMenuButton title={"FAQs"} href="/#faqs" />
        </div>

        <div className="client-loging-mobile-container">
          <ClientNavBarButton title={"Login"} href="/login" />
        </div>
      </div>
    </div>
  );
}
