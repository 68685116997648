import $ from "jquery";
import image from "../resources/img/login.svg";
import { withRouter } from "react-router-dom";

import { YellowButtonAction } from "../components/generic/YellowButtonAction";
import { InputField } from "../components/generic/InputField";
import { SimpleButton } from "../components/generic/SimpleButton";
import { ErrorLabel } from "../components/generic/ErrorLabel";

import { useState } from "react";

import { navigateToSubscribe } from "../helpers/NavigationHelpers";
import { useUserContext } from "../components/model/context/UserContext";

import { useSearchParams } from "react-router-dom";

import { navigateToLogin } from "../helpers/NavigationHelpers";
import TrackingComponent from "../components/tracking/TrackingComponent";

const NewPasswordPage = ({ setLoading }) => {
  const { changePassword } = useUserContext();
  const [resetPasswordEnabled, setResetPasswordEnabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const changePasswordAction = () => {
    const invitationToken = searchParams.get("token");
    const password = $("#password").val();

    changePassword(
      invitationToken,
      password,
      (response) => {
        setLoading(false);
        navigateToLogin();
      },
      () => {
        setLoading(false);
        setShowError(true);
      }
    );
  };

  const enableResetPasswordIfNeeded = () => {
    const password = $("#password").val();
    const repeatPassword = $("#repeat-password").val();

    const passwordValid = password.length > 5;
    const repeatPasswordValid = repeatPassword.length > 5;

    if (passwordValid && repeatPasswordValid && password === repeatPassword) {
      setResetPasswordEnabled(true);
      setShowError(false);
    } else {
      setResetPasswordEnabled(false);
      setShowError(true);
    }
  };

  var errorContent = "";

  if (showError) {
    errorContent = (
      <div className="col error-message">
        <ErrorLabel text={"Passwords don't match or are too short."} />
      </div>
    );
  }

  return (
    <div id="login" className="full-screen-item black ">
      <div className="container ">
        <div className="row login-row ">
          <div className="col-lg-6 d-flex justify-content-left align-items-center authentication-left-column-border">
            <div className="flex-column login-fields-container">
              <div className="mobile mt-100"></div>
              <p className="authentication-title mb-30">New Password</p>
              <p className="authentication-subtitle mb-60">
                Pick a new password to use for your login credentials.
              </p>
              <InputField
                placeholder={"Password"}
                id={"password"}
                type={"password"}
                onChange={enableResetPasswordIfNeeded}
              />
              <InputField
                placeholder={"Repeat password"}
                id={"repeat-password"}
                type={"password"}
                onChange={enableResetPasswordIfNeeded}
              />
              <div className="container-fluid ">
                <div className="row ">{errorContent}</div>
              </div>
              <YellowButtonAction
                title={"Change password"}
                action={changePasswordAction}
                className={"mt-60"}
                active={resetPasswordEnabled}
              />
              <div className="login-disclaimer">
                Don't have an account yet?{" "}
                <SimpleButton text={"Subscribe"} action={navigateToSubscribe} />
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-end">
            <img src={image} className="login-image desktop" alt="login" />
          </div>
        </div>
      </div>
      <TrackingComponent />
    </div>
  );
};

export default NewPasswordPage;
