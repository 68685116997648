import React, { useState } from "react";
import parse from "html-react-parser";

import upImage from "../resources/img/up.svg";
import downImage from "../resources/img/down.svg";

const FaqQuestion = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="faq-question-container" onClick={() => setIsOpen(!isOpen)}>
      <div className="row faq-question-row">
        <div className="col">
          <h1 className="faq-question-title">{title}</h1>
        </div>
        <div className="col-1 d-flex justify-content-end faq-icon-container">
          <button className="faq-btn" onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <img src={upImage} className="faq-icon" alt="social-icon" />
            ) : (
              <img src={downImage} className="faq-icon" alt="social-icon" />
            )}
          </button>
        </div>
      </div>
      {isOpen && (
        <div className="row">
          <div className="col">
            <p className="faq-question-description mb-4 mt-4">
              {parse(description)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FaqQuestion;
