export const YellowButtonAction = ({
  title,
  className = null,
  action,
  active = true,
}) => {
  var containerClass = `yellow-button-container-full ${className}`;
  if (!active) {
    containerClass += " disabled";
  }

  return (
    <div className={containerClass}>
      <div className="yellow-button">
        <button
          onClick={action}
          className="yellow-button-button"
          disabled={!active}
        >
          {title}
        </button>
      </div>
      <div className="yellow-button-background"></div>
    </div>
  );
};
