import $ from "jquery";
import image from "../resources/img/login.svg";
import React, { useState, useEffect } from "react";

import selectorImageEmpty from "../resources/img/membership-selector-empty.svg";
import selectorImageFilled from "../resources/img/membership-selector-filled.svg";
import subscribeLeftImage from "../resources/img/subscribe-left-items.svg";
import subscribeRightImage from "../resources/img/subscribe-right-items.svg";

import { YellowButtonAction } from "../components/generic/YellowButtonAction";
import { InputField } from "../components/generic/InputField";
import { SimpleButton } from "../components/generic/SimpleButton";
import { ErrorLabel } from "../components/generic/ErrorLabel";
import UserContextProvider, {
  useUserContext,
} from "../components/model/context/UserContext";
import { isEmailValid } from "../helpers/ValidatorHelper";
import {
  navigateToDashboard,
  navigateToURL,
} from "../helpers/NavigationHelpers";

import MembershipOption from "../components/MembershipOption";
import { useSearchParams } from "react-router-dom";
import TrackingComponent from "../components/tracking/TrackingComponent";

const SelectYourMembership = ({
  selectedOption,
  setSelectedOption,
  setHasSelectedOption,
}) => {
  const selectItem = (element) => {
    setSelectedOption(element);
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const option = searchParams.get("option");
  if (option) {
    setSelectedOption(option);
  }

  let membershipOptions = [
    <MembershipOption
      title={"Monthly"}
      subtitle={"No minimum commitment. Pause or cancel anytime."}
      price={"$4,595/m"}
      selected={selectedOption === "monthly"}
      action={selectItem}
      option={"monthly"}
      isFreeTrial={true}
    />,
    <MembershipOption
      title={"Quarterly"}
      subtitle={"3 months commitment. Save $500 per month."}
      price={"$3,995/m"}
      selected={selectedOption === "quarterly"}
      action={selectItem}
      option={"quarterly"}
    />,
    <MembershipOption
      title={"Yearly"}
      subtitle={"One year commitment. Save $1,000 per month."}
      price={"$3,595/m"}
      selected={selectedOption === "yearly"}
      action={selectItem}
      option={"yearly"}
    />,
  ];

  return (
    <div className="col-lg-6 d-flex justify-content-left align-items-center authentication-left-column-border">
      <div className="flex-column login-fields-container">
        <div className="mobile mt-100"></div>
        <p className="authentication-super-title mb-60">
          Select your membership plan
        </p>
        {membershipOptions}
        <div className="mobile">
          <YellowButtonAction
            title={"Continue"}
            action={() => {
              console.log("mata");
              setHasSelectedOption(true);
            }}
            className={"mt-40"}
            active={true}
          />
        </div>
      </div>
    </div>
  );
};

const AccountDetails = ({ selectedOption, setLoading }) => {
  const { createUser, addSubscription } = useUserContext();

  const [createAccountEnabled, setCreateAccountEnabled] = useState(false);
  const [showError, setShowError] = useState(false);

  const addSubscriptionAction = () => {
    const productType = selectedOption;

    setLoading(true);
    addSubscription(
      productType,
      function (response) {
        setLoading(false);

        const url = response.data.url;
        navigateToURL(url);
      },
      function () {
        setLoading(false);
        navigateToDashboard();
      }
    );
  };

  const createUserAction = () => {
    const name = $("#name").val();
    const password = $("#password").val();
    const email = $("#email").val();

    setLoading(true);
    createUser(
      email,
      name,
      password,
      function (response) {
        setLoading(false);
        addSubscriptionAction();
      },
      function () {
        setLoading(false);
        setShowError(true);
      }
    );
  };

  const enableCreateAccountIfNeeded = () => {
    const name = $("#name").val();
    const password = $("#password").val();
    const email = $("#email").val();

    const nameValid = name.length > 2;
    const passwordValid = password.length > 5;
    const emailValid = isEmailValid(email);

    if (nameValid && passwordValid && emailValid) {
      setCreateAccountEnabled(true);
    } else {
      setCreateAccountEnabled(false);
    }

    setShowError(false);
  };

  var errorContent = "";

  if (showError) {
    errorContent = (
      <div className="col error-message">
        <ErrorLabel
          text={
            "Something went wrong, please try again. Also, make sure you haven't created another account with the same email address."
          }
        />
      </div>
    );
  }

  return (
    <div className="col-lg-6 d-flex justify-content-left align-items-center">
      <div className="flex-column login-fields-container">
        <div className="mobile mt-100"></div>
        <p className="authentication-title mb-30">Client details</p>
        <p className="authentication-subtitle mb-60">
          Simple pricing. No Hidden Fees. Let’s get to work!
        </p>
        <InputField
          placeholder={"Enter your full name"}
          id={"name"}
          type={"name"}
          onChange={enableCreateAccountIfNeeded}
        />
        <InputField
          placeholder={"Enter your email address"}
          id={"email"}
          type={"email"}
          onChange={enableCreateAccountIfNeeded}
        />
        <InputField
          placeholder={"Enter your password"}
          id={"password"}
          type={"password"}
          onChange={enableCreateAccountIfNeeded}
        />
        {errorContent}
        <YellowButtonAction
          title={"Start subscription"}
          action={createUserAction}
          className={"mt-40"}
          active={createAccountEnabled}
        />
      </div>
    </div>
  );
};

const SubscribePage = ({ setLoading }) => {
  const [selectedOption, setSelectedOption] = useState("monthly");
  const { navigateToDashboardIfPossible } = useUserContext();
  const [hasSelectedOption, setHasSelectedOption] = useState(false);

  useEffect(() => {
    navigateToDashboardIfPossible();
  }, []);

  let elements = (
    <div className="row login-row ">
      <SelectYourMembership
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
      <AccountDetails selectedOption={selectedOption} setLoading={setLoading} />
    </div>
  );

  let isMobile = window.innerWidth <= 990;
  if (isMobile) {
    if (!hasSelectedOption) {
      elements = (
        <SelectYourMembership
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          setHasSelectedOption={setHasSelectedOption}
        />
      );
    } else {
      elements = (
        <AccountDetails
          selectedOption={selectedOption}
          setLoading={setLoading}
        />
      );
    }
  }

  return (
    <div id="subscribe" className="black ">
      <img src={subscribeRightImage} className="subscribe-right-image" />
      <div className="container subscribe-container">{elements}</div>
      <TrackingComponent />
    </div>
  );
};

export default SubscribePage;
