import $ from "jquery";
import image from "../resources/img/login.svg";
import { useState, useEffect } from "react";

import { YellowButtonAction } from "../components/generic/YellowButtonAction";
import { InputField } from "../components/generic/InputField";
import { SimpleButton } from "../components/generic/SimpleButton";
import { ErrorLabel } from "../components/generic/ErrorLabel";
import UserContextProvider, {
  useUserContext,
} from "../components/model/context/UserContext";

import {
  navigateToDashboard,
  navigateToResetPassword,
  navigateToSubscribe,
  navigateToDashboardWithCard,
} from "../helpers/NavigationHelpers";

import { isEmailValid } from "../helpers/ValidatorHelper";
import TrackingComponent from "../components/tracking/TrackingComponent";
import { useSearchParams } from "react-router-dom";

const LoginPage = ({ setLoading }) => {
  const { loginUser, navigateToDashboardIfPossible, fetchCurrentUser } =
    useUserContext();
  const [loginEnabled, setLoginEnabled] = useState(false);
  const [showError, setShowError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  navigateToDashboardIfPossible();

  const handleUserSuccesfullyAuthenticated = () => {
    const action = searchParams.get("action");

    if (!action) {
      // Navigate to the dashboard
      navigateToDashboard();
      return;
    }

    if (action === "card") {
      const cardId = searchParams.get("cardId");
      if (!cardId) {
        navigateToDashboard();
        return;
      }

      // Go to dashboard with card
      navigateToDashboardWithCard(cardId);
    }
  };

  const loginAction = () => {
    setLoading(true);
    const emailValue = $("#email").val();
    const passwordValue = $("#password").val();

    loginUser(
      emailValue,
      passwordValue,
      (response) => {
        setLoading(false);
        // User is authenticated go to dashboard
        handleUserSuccesfullyAuthenticated();
      },
      () => {
        setLoading(false);
        setShowError(true);
      }
    );
  };

  const enableLoginButtonIfNeeded = () => {
    const emailValue = $("#email").val();
    const passwordValue = $("#password").val();

    const emailValid = emailValue.length > 0 && isEmailValid(emailValue);
    const passwordValid = passwordValue.length > 5;
    setShowError(false);

    if (emailValid && passwordValid) {
      setLoginEnabled(true);
    } else {
      setLoginEnabled(false);
    }
  };

  let errorContainer = (
    <div className="col error-message">
      <ErrorLabel text={"Wrong email or password"} />
    </div>
  );

  if (showError === false) {
    errorContainer = <div></div>;
  }

  const handleAutoLoginIfNeeded = () => {
    setLoading(true);

    fetchCurrentUser(
      (response) => {
        setLoading(false);
        // User is authenticated go to dashboard
        handleUserSuccesfullyAuthenticated();
      },
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    handleAutoLoginIfNeeded();
  }, []);

  return (
    <div id="login" className="full-screen-item">
      <div className="container ">
        <div className="row login-row ">
          <div className="col-lg-6 d-flex justify-content-left align-items-center authentication-left-column-border">
            <div className="flex-column login-fields-container">
              <div className="mobile mt-100"></div>
              <p className="authentication-title mb-60">Welcome back</p>
              <InputField
                placeholder={"Email address"}
                id={"email"}
                type={"email"}
                onChange={enableLoginButtonIfNeeded}
              />
              <InputField
                placeholder={"Password"}
                id={"password"}
                type={"password"}
                onChange={enableLoginButtonIfNeeded}
              />
              <div className="container-fluid ">
                <div className="row ">
                  {errorContainer}
                  <div className="col d-flex justify-content-end no-margin">
                    <SimpleButton
                      text={"Forgot password"}
                      action={navigateToResetPassword}
                    />
                  </div>
                </div>
              </div>
              <YellowButtonAction
                title={"Log in"}
                action={loginAction}
                className={"mt-60"}
                active={loginEnabled}
              />
              <div className="login-disclaimer">
                Don't have an account yet?{" "}
                <SimpleButton text={"Subscribe"} action={navigateToSubscribe} />
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-end">
            <img src={image} className="login-image desktop" alt="login" />
          </div>
        </div>
      </div>
      <TrackingComponent />
    </div>
  );
};

export default LoginPage;
