import { useUserContext } from "../components/model/context/UserContext";
import { useState, useEffect } from "react";
import { YellowButtonAction } from "../components/generic/YellowButtonAction";
import LocalStorage from "../components/model/state/LocalStorageHelper";
import {
  navigateToLogin,
  navigateToURL,
  navigateToEditProfile,
  navigateToTasks,
} from "../helpers/NavigationHelpers";

import userIcon from "../resources/img/dashboard-user-icon.svg";
import rightCornerAccentIcon from "../resources/img/right-corner-accent.svg";
import designTaskIcon from "../resources/img/design-task.svg";
import billingIcon from "../resources/img/billing-icon.svg";
import subscriptionIcon from "../resources/img/subscription-icon.svg";
import MembershipOption from "../components/MembershipOption";
import CheckmarkIcon from "../resources/img/checkmark-icon.svg";
import InfoIcon from "../resources/img/info-icon.svg";
import TrackingComponent from "../components/tracking/TrackingComponent";
import goToTasksButton from "../resources/img/go-to-your-tasks.svg";

import { YellowSmallButtonAction } from "../components/generic/YellowButtonSmallAction";
import { format } from "date-fns";
import { useSearchParams } from "react-router-dom";

function currencyFormat(num) {
  return "$" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

const priceForProductType = (product_type) => {
  if (product_type === "monthly") {
    return "$4,595/month";
  }

  if (product_type === "quarterly") {
    return "$3,995/month";
  }

  if (product_type === "yearly") {
    return "$3,595/month";
  }

  return "";
};

const DashboardSimpleButton = ({ title, action, className }) => {
  let buttonClassName = "dashboard-arrow-button";

  if (className) {
    buttonClassName += " " + className;
  }

  return (
    <button className={buttonClassName} onClick={action}>
      {title}
    </button>
  );
};

const DashboardPlainButton = ({ title, action, className }) => {
  let buttonClassName = "dashboard-plain-button";

  if (className) {
    buttonClassName += " " + className;
  }

  return (
    <button className={buttonClassName} onClick={action}>
      {title}
    </button>
  );
};

const UserProfileElement = ({ name, email, date, editProfileAction }) => {
  return (
    <div className="dashboard-profile-element">
      <div className="container dashboard-profile-container">
        <div className="d-flex justify-content-between row d-flex flex-row">
          <div className="dashboard-info-container">
            <div>
              <div className="dashboard-user-image-container">
                <img
                  src={userIcon}
                  className="dashboard-user-image"
                  alt="user"
                />
              </div>
              <div className="d-flex flex-column dashboard-user-details">
                <span className="dashboard-name">{name}</span>
                <span className="dashboard-email">{email}</span>
              </div>
            </div>
            <div>
              <img
                src={rightCornerAccentIcon}
                className="dashboard-user-right-corner-image"
                alt="accent"
              />
            </div>
          </div>
        </div>

        <div className="row d-flex dasboard-second-row">
          <div className="dashboard-info-container d-flex justify-content-between">
            <div className="d-flex justify-content-start">
              <div className="d-flex flex-column justify-content-start dashboard-user-details">
                <span className="dashboard-member">Designesy member</span>
                <span className="dashboard-small-text">{date}</span>
              </div>
            </div>
            <div className="dasboard-info-right-action">
              <DashboardSimpleButton
                title={"Edit profile >"}
                className={null}
                action={editProfileAction}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DesignTaskElement = ({ date, viewTaskAction }) => {
  return (
    <div className="dashboard-task-element">
      <div className="container dashboard-profile-container">
        <div className="d-flex justify-content-between row d-flex flex-row">
          <div className="dashboard-info-container">
            <div>
              <div className="dashboard-user-image-container">
                <img
                  src={designTaskIcon}
                  className="dashboard-user-image"
                  alt="design"
                />
              </div>
              <div className="d-flex flex-column dashboard-title-container">
                <span className="dashboard-card-task-title">Design tasks</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row d-flex">
          <div className="col">
            <div className="dashboard-task-process">
              Subscribe > Request > Receive > Revise
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <button
              className="invisible-button go-to-tasks-button"
              onClick={viewTaskAction}
            >
              <img src={goToTasksButton} className="" alt="tasks" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const BillingElement = ({ viewInvoicesAction, subscriptionDetails }) => {
  var billingDetails = "";
  var invoiceTotal = "";

  if (
    subscriptionDetails &&
    (subscriptionDetails.active === true || subscriptionDetails.paused === true)
  ) {
    var subscriptionText = "";

    if (subscriptionDetails.paused === true) {
      subscriptionText = "Resume Subscription";
    } else {
      // The subscription is active
      subscriptionText = format(
        new Date(subscriptionDetails.next_subscription_date * 1000),
        "dd MMMM yyyy"
      );
    }

    const price = subscriptionDetails.price / 100;
    invoiceTotal = currencyFormat(price);

    billingDetails = (
      <div className="dashboard-billing-element">
        <div className="container dashboard-profile-container">
          <div className="d-flex justify-content-between row d-flex flex-row">
            <div className="dashboard-info-container">
              <div>
                <div className="dashboard-user-image-container">
                  <img
                    src={billingIcon}
                    className="dashboard-user-image"
                    alt="billing"
                  />
                </div>
                <div className="d-flex flex-column dashboard-title-container">
                  <span className="dashboard-card-title">Billing</span>
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex dasboard-billing-second-row">
            <div className="d-flex flex-column justify-content-start dashboard-user-details">
              <span className="dashboard-small-text black-text mt-20">
                Next invoice issue date
              </span>
              <span className="dashboard-card-title black-text mt-10">
                {subscriptionText}
              </span>
              <span className="dashboard-small-text black-text mt-20">
                Invoice total
              </span>
              <span className="dashboard-card-title black-text mt-10">
                {invoiceTotal}
              </span>
              <div className="dashboard-separator mt-20"></div>
              <span className="dashboard-small-text black-text mt-20">
                Payment method
              </span>
              <DashboardPlainButton
                title={"Update payment"}
                action={viewInvoicesAction}
                className={"mt-10"}
              />
            </div>
          </div>

          <div className="row d-flex dashboard-billing-third-row">
            <div className="dashboard-info-container d-flex justify-content-between">
              <div className="d-flex justify-content-start"></div>
              <div className="dasboard-info-right-action">
                <DashboardSimpleButton
                  title={"View invoices >"}
                  className={"blue"}
                  action={viewInvoicesAction}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div>{billingDetails}</div>;
};

const StatusIndicator = ({ subscription }) => {
  var text = "Inactive";
  var className = "inactive-subscription";

  if (subscription) {
    if (subscription.status === "active") {
      text = "Active";
      className = "active-subscription";
    }

    if (subscription.status === "paused") {
      text = "Paused";
      className = "paused-subscription";
    }

    if (subscription.status === "pending") {
      text = "Pending";
      className = "inactive-subscription";
    }

    if (subscription.status === "trialing") {
      const formattedDate = format(
        new Date(subscription.trial_end * 1000),
        "dd MMMM yyyy"
      );
      text = "Free until " + formattedDate;
      className = "active-subscription";
    }
  }

  const finalClassName = "subscription-status " + className;

  return (
    <div className={finalClassName}>
      <p className="status-text">{text}</p>
    </div>
  );
};

const SelectMembershipElement = ({
  selectedOption,
  setSelectedOption,
  startSubscriptionAction,
}) => {
  const selectItem = (element) => {
    setSelectedOption(element);
  };

  let membershipOptions = [
    <MembershipOption
      title={"Monthly"}
      subtitle={"No minimum commitment. Pause or cancel anytime."}
      price={"$4,495/m"}
      selected={selectedOption === "monthly"}
      action={selectItem}
      option={"monthly"}
      customClass={"small-option"}
      isFreeTrial={true}
    />,
    <MembershipOption
      title={"Quarterly"}
      subtitle={"3 months commitment. Save $500 per month."}
      price={"$3,995/m"}
      selected={selectedOption === "quarterly"}
      action={selectItem}
      option={"quarterly"}
      customClass={"small-option"}
    />,
    <MembershipOption
      title={"Yearly"}
      subtitle={"One year commitment. Save $,1000 per month."}
      price={"$3,594/m"}
      selected={selectedOption === "yearly"}
      action={selectItem}
      option={"yearly"}
      customClass={"small-option"}
    />,
  ];

  return (
    <div className="dashboard-membership-selector">
      <div className="flex-column">
        <div className="options">{membershipOptions}</div>

        <div className="dashboard-start-membership">
          <YellowButtonAction
            title={"Start subscription"}
            action={startSubscriptionAction}
            className={"mt-20"}
            active={true}
          />
        </div>
      </div>
    </div>
  );
};

const MembershipBenefit = ({ title }) => {
  return (
    <div className="dashboard-membership-benefit d-flex justify-content-start mt-10">
      <div className="dashboard-icon-container">
        <img
          src={CheckmarkIcon}
          className="dashboard-membership-checkmark"
          alt="billing"
        />
      </div>
      <div className="dashboard-membership-text">{title}</div>
    </div>
  );
};

const MembershipElement = ({
  subscription,
  pauseSubscription,
  resumeSubscription,
  cancelSubscription,
}) => {
  var benefits = [
    <MembershipBenefit title={"Unlimited requests"} />,
    <MembershipBenefit title={"Unlimited brands"} />,
    <MembershipBenefit title={"Unlimited users"} />,
  ];

  let type = subscription.product_type;
  let price = priceForProductType(type);

  if (type === "monthly") {
    benefits.push(<MembershipBenefit title={"Pause or cancel anytime"} />);
  }

  var action = (
    <YellowSmallButtonAction
      title={"Pause Subscription"}
      action={pauseSubscription}
    />
  );

  if (subscription.status === "paused") {
    action = (
      <YellowSmallButtonAction
        title={"Resume Subscription"}
        action={resumeSubscription}
      />
    );
  }

  if (type === "yearly" || type === "quarterly") {
    action = "";
  }

  return (
    <div className="dashboard-membership-container">
      <div className="flex-column">
        <div className="dashboard-membership-type d-flex justify-content-between">
          <div className="dashboard-subscription-type">{type}</div>
          <div className="dashboard-subscription-price">{price}</div>
        </div>
        <div className="dashboard-separator mt-20 mb-20"></div>

        <div className="dashboard-whats-included">What's included:</div>
        {benefits}
        <div className="d-flex justify-content-between mt-40">
          <div className="dashboard-cancel-subscription-container">
            <DashboardPlainButton
              title={"Cancel"}
              action={cancelSubscription}
            />
          </div>
          <div className="dashboard-subscription-price">{action}</div>
        </div>
      </div>
    </div>
  );
};

const SubscriptionElement = ({
  subscription,
  cancelSubscriptionAction,
  pauseSubscriptionAction,
  resumeSubscriptionAction,
  startSubscriptionAction,
  selectedOption,
  setSelectedOption,
}) => {
  const subscribeContainer = (
    <SelectMembershipElement
      selectedOption={selectedOption}
      setSelectedOption={setSelectedOption}
      startSubscriptionAction={startSubscriptionAction}
    />
  );

  const membershipContainer = (
    <MembershipElement
      subscription={subscription}
      pauseSubscription={pauseSubscriptionAction}
      resumeSubscription={resumeSubscriptionAction}
      cancelSubscription={cancelSubscriptionAction}
    />
  );

  var containerToDisplay = subscribeContainer;
  if (
    subscription &&
    (subscription.active === true || subscription.status === "paused")
  ) {
    containerToDisplay = membershipContainer;
  }

  if (subscription && subscription.status === "pending") {
    containerToDisplay = "";
  }

  var infoContainer = (
    <div className="dashboard-info-container">
      <div>
        <div className="dashboard-user-image-container">
          <img
            src={subscriptionIcon}
            className="dashboard-user-image"
            alt="subscription"
          />
        </div>
        <div className="d-flex flex-column dashboard-title-container">
          <span className="dashboard-card-title">Subscription</span>
        </div>
      </div>
    </div>
  );

  let isMobile = window.innerWidth <= 990;
  if (subscription && subscription.status === "trialing" && isMobile) {
    infoContainer = "";
  }

  return (
    <div className="dashboard-billing-element">
      <div className="container dashboard-profile-container">
        <div className="row">
          <div className="d-flex justify-content-between">
            {infoContainer}
            <div className="status mt-10 mr-20">
              <StatusIndicator subscription={subscription} />
            </div>
          </div>
        </div>

        <div className="row d-flex">{containerToDisplay}</div>
      </div>
    </div>
  );
};

const DashboardPage = ({ setLoading }) => {
  const {
    currentUser,
    fetchCurrentSubscription,
    fetchBillingPortalURL,
    cancelSubscription,
    resumeSubscription,
    pauseSubscription,
    addSubscription,
    fetchSubscriptionDetails,
    plankaAccessDetails,
    plankaAccessDirectlyToCard,
  } = useUserContext();

  const [subscription, setSubscription] = useState(null);
  const [billingPortalURL, setBillingPortalURL] = useState(null);
  const [selectedOption, setSelectedOption] = useState("monthly");
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  // If the current user is not set redirect to login
  if (!currentUser()) {
    navigateToLogin();
  }

  const handleNavigateToCard = (cardId) => {
    setLoading(true);

    plankaAccessDirectlyToCard(
      cardId,
      (response) => {
        setLoading(false);
        console.log(response);
        const url = response.data.details.url;
        navigateToURL(url);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const handlePageLoaded = () => {
    // Navigate to card if needed
    const cardId = searchParams.get("cardId");
    if (cardId) {
      handleNavigateToCard(cardId);
    }
  };

  const loadCurrentSubscription = () => {
    setLoading(true);

    fetchCurrentSubscription(
      (response) => {
        console.log(response);
        setLoading(false);
        setSubscription(response.data.subscription);

        // Load the billing portal if exists
        loadBillingPortal();

        // Subscription details
        getSubscriptionDetails();
      },
      () => {
        setLoading(false);
        alert("There's something wrong with the app, please try again!");
      }
    );
  };

  const getSubscriptionDetails = () => {
    setLoading(true);

    fetchSubscriptionDetails(
      (response) => {
        setLoading(false);
        setSubscriptionDetails(response.data.details);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const loadCurrentSubscriptionAfterSubscriptionChange = () => {
    setLoading(true);
    setTimeout(() => {
      loadCurrentSubscription();
    }, 5000);
  };

  const loadBillingPortal = () => {
    setLoading(true);

    fetchBillingPortalURL(
      (response) => {
        setLoading(false);
        setBillingPortalURL(response.data.url);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const cancelSubscriptionAction = () => {
    setLoading(true);

    cancelSubscription(
      (response) => {
        setLoading(false);
        loadCurrentSubscriptionAfterSubscriptionChange();
      },
      () => {
        setLoading(false);
      }
    );
  };

  const pauseSubscriptionAction = () => {
    setLoading(true);

    pauseSubscription(
      (response) => {
        setLoading(false);
        loadCurrentSubscriptionAfterSubscriptionChange();
      },
      () => {
        setLoading(false);
      }
    );
  };

  const resumeSubscriptionAction = () => {
    setLoading(true);

    resumeSubscription(
      (response) => {
        setLoading(false);
        loadCurrentSubscriptionAfterSubscriptionChange();
      },
      () => {
        setLoading(false);
      }
    );
  };

  const goToBillingPortal = () => {
    if (billingPortalURL) {
      navigateToURL(billingPortalURL);
    }
  };

  const goToTasks = () => {
    setLoading(true);

    plankaAccessDetails(
      (response) => {
        setLoading(false);

        const url = response.data.details.url;
        navigateToTasks(url);
      },
      () => {
        console.log("error");
        setLoading(false);
      }
    );
  };

  const purchaseSelectedSubscription = () => {
    setLoading(true);

    addSubscription(
      selectedOption,
      function (response) {
        setLoading(false);

        const url = response.data.url;
        navigateToURL(url);
      },
      function () {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    loadCurrentSubscription();
    handlePageLoaded();
  }, []);

  var errorElement = "";

  if (subscription && subscription.status === "payment_failed") {
    errorElement = (
      <div className="row dashboard-error-row">
        <div className="d-flex justify-content-between dashboard-error-container-items">
          <div className="">
            <div className="d-flex justify-content-start">
              <div className="dashboard-info-icon">
                <img
                  src={InfoIcon}
                  className="dashboard-info-icon"
                  alt="info"
                />
              </div>
              <div className="d-flex flex-column dashboard-error-container">
                <span className="dashboard-error-text">
                  Our last 3 attempted charges were unsuccessful. Please update
                  your paymemt payment source to contnue the subscription.
                </span>
              </div>
            </div>
          </div>
          <div className="mr-20">
            <button
              className="dashboard-update-payment-button"
              onClick={goToBillingPortal}
            >
              Update payment
            </button>
          </div>
        </div>
      </div>
    );
  }

  const name = currentUser().name ?? "";
  const email = currentUser().email ?? "";
  var taskDate = "";
  if (subscription) {
    taskDate =
      "Starting " + format(new Date(subscription.updated_at), "MMMM d yyyy");
  }

  return (
    <div className="dashboard-page">
      <div className="container dashboard-container">
        {errorElement}
        <div className="row dashboard-row">
          <div className="col-lg-7">
            <UserProfileElement
              name={name}
              email={email}
              date={
                "since " +
                format(new Date(currentUser().created_at), "MMMM yyyy")
              }
              editProfileAction={navigateToEditProfile}
            />
          </div>
          <div className="col-lg-5 dashboard-margin-top-mobile">
            <DesignTaskElement date={taskDate} viewTaskAction={goToTasks} />
          </div>
        </div>
        <div className="row dashboard-row">
          <div className="col-lg-7">
            <SubscriptionElement
              subscription={subscription}
              cancelSubscriptionAction={cancelSubscriptionAction}
              pauseSubscriptionAction={pauseSubscriptionAction}
              resumeSubscriptionAction={resumeSubscriptionAction}
              startSubscriptionAction={purchaseSelectedSubscription}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
          </div>
          <div className="col-lg-5 dashboard-margin-top-mobile">
            <BillingElement
              viewInvoicesAction={goToBillingPortal}
              subscriptionDetails={subscriptionDetails}
            />
          </div>
        </div>
      </div>
      <TrackingComponent />
    </div>
  );
};

export default DashboardPage;
