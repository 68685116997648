import NavigationBar from "./NavigationBar";
import MobileMenu from "./Menu";
import $ from "jquery";

export default function Navigation({ showMenu, showLogout }) {
  return (
    <div id="navigation-bar-container-outter-view">
      <MobileMenu showMenu={showMenu} showLogout={showLogout} />
    </div>
  );
}
