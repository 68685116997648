import $ from "jquery";
import image from "../resources/img/login.svg";

import { YellowButtonAction } from "../components/generic/YellowButtonAction";
import { InputField } from "../components/generic/InputField";
import { SimpleButton } from "../components/generic/SimpleButton";
import { ErrorLabel } from "../components/generic/ErrorLabel";

import { useState, useEffect } from "react";

import { navigateToSubscribe } from "../helpers/NavigationHelpers";
import { useUserContext } from "../components/model/context/UserContext";
import { isEmailValid } from "../helpers/ValidatorHelper";
import TrackingComponent from "../components/tracking/TrackingComponent";

const ResetPasswordPage = ({ setLoading }) => {
  const { resetPassword, navigateToDashboardIfPossible } = useUserContext();

  const [emailSent, setEmailSent] = useState(false);
  const [sendLinkEnabled, setSendLinkEnabled] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    navigateToDashboardIfPossible();
  }, []);

  const resetPasswordAction = () => {
    let email = $("#email").val();
    setLoading(true);

    resetPassword(
      email,
      (response) => {
        setLoading(false);
        setEmailSent(true);
      },
      () => {
        setLoading(false);
        setShowError(true);
      }
    );
  };

  const resetInitialState = () => {
    $("#email").val("");
    setShowError(false);
    setEmailSent(false);
    setSendLinkEnabled(false);
  };

  const enableActionIfNeeded = () => {
    const emailValue = $("#email").val();
    const emailValid = emailValue.length > 0 && isEmailValid(emailValue);

    setShowError(false);
    setSendLinkEnabled(emailValid);
  };

  let errorContainer = (
    <div className="col error-message">
      <ErrorLabel text={"Make sure you've entered the correct email address"} />
    </div>
  );

  if (showError === false) {
    errorContainer = "";
  }

  const initialState = (
    <div className="flex-column login-fields-container">
      <div className="mobile mt-100"></div>
      <p className="authentication-title mb-30">Reset Password</p>
      <p className="authentication-subtitle mb-60">
        Enter the email address associated with your account and we’ll send you
        a link to reset your password.
      </p>
      <InputField
        placeholder={"Email address"}
        id={"email"}
        type={"email"}
        onChange={enableActionIfNeeded}
      />
      {/* {errorContainer} */}
      <YellowButtonAction
        title={"Send link"}
        action={resetPasswordAction}
        className={"mt-60"}
        active={sendLinkEnabled}
      />
      <div className="login-disclaimer">
        Don't have an account yet?{" "}
        <SimpleButton text={"Subscribe"} action={navigateToSubscribe} />
      </div>
    </div>
  );

  const emailSentState = (
    <div className="flex-column login-fields-container">
      <div className="mobile mt-100"></div>
      <p className="authentication-title mb-30">Check your email</p>
      <p className="authentication-subtitle mb-60">
        We have sent a password recover instructions to your email.
      </p>
      <div className="authentication-line"></div>
      <div className="authentication-subtitle mt-40 mb-10">
        Did not receive the email?
      </div>
      <SimpleButton
        text={"Try another email address"}
        action={resetInitialState}
      />

      <YellowButtonAction
        title={"Resend link"}
        link={"#"}
        className={"mt-60"}
        active={sendLinkEnabled}
      />
      <div className="login-disclaimer">
        Don't have an account yet?{" "}
        <SimpleButton text={"Subscribe"} action={navigateToSubscribe} />
      </div>
    </div>
  );

  let currentState = initialState;
  if (emailSent) {
    currentState = emailSentState;
  }

  return (
    <div id="login" className="full-screen-item black ">
      <div className="container ">
        <div className="row login-row ">
          <div className="col-lg-6 d-flex justify-content-left align-items-center authentication-left-column-border">
            {currentState}
          </div>
          <div className="col-lg-6 d-flex justify-content-end">
            <img src={image} className="login-image desktop" alt="login" />
          </div>
        </div>
      </div>
      <TrackingComponent />
    </div>
  );
};

export default ResetPasswordPage;
