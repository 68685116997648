export const YellowSmallButtonAction = ({
  title,
  action,
  className = null,
}) => {
  return (
    <div className={`yellow-button-container-small ${className}`}>
      <div className="yellow-button-small">
        <button onClick={action} className="yellow-button-button-small">
          {title}
        </button>
      </div>
      <div className="yellow-button-background-small"></div>
    </div>
  );
};
