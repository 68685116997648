import $ from "jquery";

import TrackingComponent from "../components/tracking/TrackingComponent";
import BlogPostItem from "../components/generic/BlogPostItem";
import { blogPostsArray } from "../components/blog-posts/BlogPostsBuilder";

const BlogPage = ({ setLoading }) => {
  let blogPosts = [];
  blogPostsArray().forEach((element) => {
    const postComponent = (
      <>
        <div className="col-lg-5">
          <BlogPostItem blogPost={element()} />
        </div>
        <div className="col-lg-1"></div>
      </>
    );

    blogPosts.push(postComponent);
  });

  return (
    <div className="blog-page white">
      <div className="container mb-60">
        <div className="row">
          <div className="col-lg-8">
            <div className="blog-title">
              Design is much more than just pixels on a screen
            </div>
          </div>
        </div>

        <div className="row">{blogPosts}</div>
      </div>

      <div className="pb-60"></div>
      <TrackingComponent />
    </div>
  );
};

export default BlogPage;
