import Cookies from "js-cookie";

class LocalStorage {
  static setAuthenthicationToken(access_token) {
    localStorage.setItem("access_token", access_token);
  }

  static getAuthenticationToken() {
    return localStorage.getItem("access_token");
  }

  static setRefreshToken(refresh_token) {
    localStorage.setItem("refresh_token", refresh_token);
  }

  static getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }

  static setCurrentUser(user) {
    let strValue = JSON.stringify(user);
    localStorage.setItem("current_user", strValue);
  }

  static getCurrentUser() {
    let strValue = localStorage.getItem("current_user");
    const user = JSON.parse(strValue);

    return user;
  }

  static clearData() {
    this.setAuthenthicationToken(null);
    this.setRefreshToken(null);
    this.setCurrentUser(null);

    // Logout from dashboard
    Cookies.remove("accessToken", {
      domain: ".designesy.com",
    });

    Cookies.remove("accessTokenVersion", {
      domain: ".designesy.com",
    });
  }
}

export default LocalStorage;
