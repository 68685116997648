import logoimg from "../../resources/img/logo.svg";
import menuimg from "../../resources/img/menu-button.svg";
import $ from "jquery";
import LocalStorage from "../model/state/LocalStorageHelper";
import { navigateToLogin } from "../../helpers/NavigationHelpers";

function NavBarButton({ title, href }) {
  return (
    <div className="nav-bar-button-container d-flex">
      <a className="nav-bar-button" href={href}>
        {title}
      </a>
    </div>
  );
}

export function ClientNavBarButton({ title, href }) {
  return (
    <div className="nav-bar-button-container d-flex client-button">
      <a className="nav-bar-button white-small-text mr-30 ml-30" href={href}>
        {title}
      </a>
    </div>
  );
}

function MobileMenuButton() {
  const onClick = () => {
    // Show hide menu container
    $("#mobile-menu-links-container").toggle();
    $("#home").toggle();
  };

  return (
    <div className="mobile-button-container d-flex" onClick={onClick}>
      <img
        src={menuimg}
        className="menu-button-mobile d-flex align-self-center"
        alt="menu"
      />
    </div>
  );
}

export default function NavigationBar({ showMenu, showLogout }) {
  let menu = <div></div>;

  const logoutAction = () => {
    LocalStorage.clearData();
    navigateToLogin();
  };

  if (showMenu) {
    menu = (
      <div className="col d-flex align-items-center justify-content-end">
        <div className="navigation-actions-desktop">
          <div className="d-flex flex-row-reverse">
            <ClientNavBarButton title="Login" href="/login" />
            <NavBarButton title="FAQs" href="/#faqs" />
            <NavBarButton title="Pricing" href="/#plans" />
            <NavBarButton title="Recent work" href="/#our-work" />
            <NavBarButton title="Blog" href="/blog" />
          </div>
        </div>

        <div className="navigation-actions-mobile">
          <div className="d-flex flex-row-reverse">
            <MobileMenuButton />
          </div>
        </div>
      </div>
    );
  }

  if (showLogout) {
    menu = (
      <div className="col d-flex align-items-center justify-content-end">
        <div className="navigation-actions">
          <div className="d-flex flex-row-reverse">
            <div className="nav-bar-button-container d-flex">
              <button className="logout-button" onClick={logoutAction}>
                Log Out
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="navigation-bar">
      <div className="container navigation-bar-container">
        <div className="row">
          <div className="col-2 logo-column align-self-center">
            <a href="/">
              <img src={logoimg} className="logo d-flex" alt="logo" />
            </a>
          </div>
          {menu}
        </div>
      </div>
    </div>
  );
}
