import Carousel from "react-bootstrap/Carousel";
import $ from "jquery";

import bigHeaderImg from "../resources/img/main-header-big.png";

import designerImage from "../resources/img/designer.svg";
import clientImage from "../resources/img/client.svg";

import designerImageMobile from "../resources/img/designer-mobile.svg";
import clientImageMobile from "../resources/img/client-mobile.svg";
import clientImageTablet from "../resources/img/client-tablet.svg";
import headerImgMobile from "../resources/img/main-header-mobile.png";

import toolImage from "../resources/img/tool.svg";
import toolImageMobile from "../resources/img/tool-mobile.svg";

import benefitsRectangleImage from "../resources/img/benefits-rectangle.svg";
import benefitsIllustrationImage from "../resources/img/benefits-illustration.svg";

import benefitsRectangleImageMobile from "../resources/img/benefits-rectangle-mobile.svg";
import benefitsIllustrationImageMobile from "../resources/img/benefits-illustration-mobile.svg";

import caseStudy1 from "../resources/img/case-study-asset-1.png";
import caseStudy2 from "../resources/img/case-study-asset-2.png";
import caseStudy3 from "../resources/img/case-study-asset-3.png";
import caseStudy4 from "../resources/img/case-study-asset-4.png";

import flowerImage from "../resources/img/flower.svg";
import servicesImage from "../resources/img/services.svg";

import bookACallImage from "../resources/img/book-a-call-image.svg";
import mostPopularImage from "../resources/img/most-popular.svg";

import FaqQuestion from "../components/FaqQuestion";
import { YellowButton } from "../components/generic/YellowButton";
import { YellowSmallButton } from "../components/generic/YelloButtonSmall";
import { navigateToSubscribeWithOption } from "../helpers/NavigationHelpers";
import { YellowSmallButtonAction } from "../components/generic/YellowButtonSmallAction";
import TrackingComponent from "../components/tracking/TrackingComponent";

import howItWorks from "../resources/img/how-it-works.svg";
import seeHowItWorks from "../resources/img/see-how-it-works.svg";
import designAddsValue from "../resources/img/design-adds-value.svg";
import howItWorksMobile from "../resources/img/how-it-works-mobile.svg";

import trustedBy from "../resources/img/trusted-by.svg";
import freeTrial from "../resources/img/free-trial.svg";
import { useState, useRef } from "react";

const BorderedButton = ({ title, link, className = null }) => {
  return (
    <div className={`bordered-button-container ${className}`}>
      <div className="bordered-button">
        <a href={link} target="_blank">
          {title}
        </a>
      </div>
    </div>
  );
};

const HomePageTitleHeader = () => {
  return (
    <div className="home-page-header-title-container mt-60">
      <div className="home-page-title d-title">
        We design,
        <img
          src={designerImage}
          className="designer-header-text-image"
          alt="menu"
        />{" "}
        you<br></br> request and revise.
        <img
          src={clientImage}
          className="client-header-text-image"
          alt="menu"
        />{" "}
        <img
          src={clientImageTablet}
          className="client-header-text-image-tablet"
          alt="menu"
        />{" "}
      </div>
      <div className="home-page-subtitle mt-40 subtitle">
        Subscription-based design services (UX/UI & branding).
      </div>
      <div className="home-page-actions mt-40">
        <YellowButton
          title={"Try for Free"}
          link={"/subscribe?option=monthly"}
        />
      </div>
    </div>
  );
};

const HomePageTitleHeaderMobile = () => {
  return (
    <div className="home-page-header-title-container-mobile">
      <div className="home-page-title title">
        We design,
        <img
          src={designerImageMobile}
          className="designer-header-text-image-mobile"
          alt="menu"
        />{" "}
        you<br></br> request and revise.
        <img
          src={clientImageMobile}
          className="client-header-text-image-mobile"
          alt="menu"
        />{" "}
      </div>
      <div className="home-page-subtitle mt-40 subtitle">
        Subscription-based design<br></br>services (UX/UI & branding).
      </div>
      <div className="home-page-actions mt-40">
        <YellowButton
          title={"Try for Free"}
          link={"/subscribe?option=monthly"}
        />
      </div>
    </div>
  );
};

const HomePageAssetHeader = () => {
  return (
    <div className="home-page-header-asset-container">
      <img src={bigHeaderImg} className="main-page-header-image" alt="menu" />
    </div>
  );
};

const HomePageAssetHeaderMobile = () => {
  return (
    <div className="home-page-header-asset-container-mobile mt-40 mb-60">
      <img
        src={headerImgMobile}
        className="main-page-header-image-mobile"
        alt="menu"
      />
    </div>
  );
};

const Header = () => {
  return (
    <div className="black">
      <div className="container">
        <div className="row" id="fold-one">
          <div className="col-lg-6 d-inline-flex justify-content-start align-items-center main-left-column">
            <HomePageTitleHeader />
            <HomePageTitleHeaderMobile />
          </div>

          <div className="col-lg-6">
            <HomePageAssetHeader />
            <HomePageAssetHeaderMobile />
          </div>
        </div>
      </div>
    </div>
  );
};

// Process

const Process = () => {
  const playVideo = () => {
    var elem = document.getElementById("video");
    elem.currentTime = 0;

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
    elem.play();

    $("video").on(
      "fullscreenchange webkitfullscreenchange mozfullscreenchange",
      function () {
        var elem = document.getElementById("video");
        if (elem.currentTime === 0) {
          elem.play();
        } else {
          elem.pause();
        }
      }
    );
  };

  return (
    <div className="process">
      <video controls id="video" allowfullcreen="true">
        <source
          src="https://firebasestorage.googleapis.com/v0/b/desigesy.appspot.com/o/designesy-how-to.mp4?alt=media&token=fe807dcc-b86a-42a6-9912-fe14e4e2ff61"
          type="video/mp4"
        ></source>
      </video>
      <div className="container">
        <div className="row mb-40">
          <div className="col-lg-6 pt-60">
            <button
              onClick={playVideo}
              className="invisible-button process-see"
            >
              <img src={seeHowItWorks} className="process-see" alt="menu" />
            </button>
          </div>
          <div className="col-lg-6 pt-60">
            <img src={designAddsValue} className="process-design" alt="menu" />
          </div>
        </div>

        <div className="row">
          <div className="col desktop">
            <img src={howItWorks} className="process-image" alt="menu" />
          </div>
          <div className="col mobile">
            <img src={howItWorksMobile} className="process-image" alt="menu" />
          </div>
        </div>
      </div>
    </div>
  );
};

// Perks

const PerksBlock = ({ title, description }) => {
  return (
    <div className="perk-block">
      <div className="perk-block-title">{title}</div>
      <div className="perk-block-description">{description}</div>
    </div>
  );
};

const Perks = () => {
  return (
    <div className="perks-container">
      <div className="container">
        <div className="row desktop trusted-by">
          <img src={trustedBy} className="full-width" alt="menu" />
        </div>
        <div className="row">
          <div className="col-12 perks-col">
            <div className="section mb-80">
              Lightning-fast digital design services, all for one flat monthly
              fee.
            </div>
            <PerksBlock
              title={"No meetings!"}
              description={
                "Say goodbye to the endless time wasted in meetings and embrace an asynchronous approach, where everything happens on your schedule."
              }
            />
            <PerksBlock
              title={"Complete control."}
              description={
                "Prioritize your design tasks, view their progress in real-time, and revise & comment on every pixel of the produced designs."
              }
            />
            <PerksBlock
              title={"Working in a team?"}
              description={
                "You can invite more people to your board so anyone can submit requests and track progress."
              }
            />
          </div>
        </div>
      </div>

      <div className="mobile">
        <img src={toolImageMobile} className="tool-image-mobile" alt="menu" />
      </div>

      <div className="desktop">
        <img src={toolImage} className="tool-image" alt="menu" />
      </div>
      <div className="parkes-bottom-space"></div>
    </div>
  );
};

const Benefits = () => {
  return (
    <div className="benefits-container">
      <div className="benefits-round-background">
        <div className="container">
          <div className="row">
            <div className="col-lg-9 benefits-left-side">
              <div className="section white-text">Membership benefits</div>
              <div className="member-benefits-description">
                High-quality designs, tailored to your needs and preferences,
                with fast turnaround times and affordable pricing that you can
                pause or cancel anytime.
              </div>
              <div className="">
                <YellowButton
                  title={"Try for Free"}
                  link={"/subscribe?option=monthly"}
                  className="mt-32"
                />
              </div>

              <div className="benefits-block">
                <div className="container desktop">
                  <img
                    src={benefitsRectangleImage}
                    className="benefits-rectangle-image"
                    alt="menu"
                  />
                  <div className="row benefit-row">
                    <div className="col-6 benefit-title">
                      Unique & exclusive
                    </div>
                    <div className="col-6 benefit-description">
                      Every design we create is crafted specifically for you and
                      is entirely yours.
                    </div>
                  </div>
                  <div className="row benefit-row">
                    <div className="col-6 benefit-title">Fixed price</div>
                    <div className="col-6 benefit-description">
                      No surprises. Pay a fixed monthly rate that remains
                      constant.
                    </div>
                  </div>

                  <div className="row benefit-row">
                    <div className="col-6 benefit-title">Flexible & easy</div>
                    <div className="col-6 benefit-description">
                      Use the service when you needed, and pause or cancel
                      anytime.
                    </div>
                  </div>
                </div>

                <div className="container mobile">
                  <img
                    src={benefitsRectangleImageMobile}
                    className="benefits-rectangle-image-mobile"
                    alt="menu"
                  />
                  <div className="row benefit-row">
                    <div className="benefit-title">Unique and all yours</div>
                    <div className="benefit-description">
                      Each of your designs is made especially for you and is
                      100% yours.
                    </div>
                  </div>
                  <div className="row benefit-row">
                    <div className="benefit-title">Fixed monthly rate</div>
                    <div className="benefit-description">
                      No surprises here! Pay the same fixed price each month.
                    </div>
                  </div>

                  <div className="row benefit-row">
                    <div className="benefit-title">Flexible and scalable</div>
                    <div className="benefit-description">
                      Scale up or down as needed, and pause or cancel at
                      anytime.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        src={benefitsIllustrationImage}
        className="benefits-illustration-image desktop"
        alt="menu"
      />
      <img
        src={benefitsIllustrationImageMobile}
        className="benefits-illustration-image-mobile mobile"
        alt="menu"
      />
    </div>
  );
};

const CaseStudiesDesktop = () => {
  return (
    <div className="desktop">
      <div className="row featured-projects-case-studies">
        <div className="col-lg-6 case-study-item">
          <a
            href="https://www.figma.com/proto/TwZ4w5VoPtoHsOeDQIrafX/Case-studies?node-id=1-139&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=1%3A139"
            target="_blank"
          >
            <img src={caseStudy1} className="case-study" alt="menu" />
          </a>
        </div>

        <div className="col-lg-6 case-study-item">
          <a
            href="https://www.figma.com/proto/TwZ4w5VoPtoHsOeDQIrafX/Case-studies?node-id=1-275&scaling=min-zoom&page-id=1%3A168&starting-point-node-id=1%3A275"
            target="_blank"
          >
            <img src={caseStudy2} className="case-study" alt="menu" />
          </a>
        </div>
      </div>

      <div className="row featured-projects-case-studies mb-80">
        <div className="col-lg-6 case-study-item">
          <a
            href="https://www.figma.com/proto/TwZ4w5VoPtoHsOeDQIrafX/Case-studies?node-id=1-409&scaling=min-zoom&page-id=1%3A169&starting-point-node-id=1%3A409"
            target="_blank"
          >
            <img src={caseStudy3} className="case-study" alt="menu" />
          </a>
        </div>

        <div className="col-lg-6 case-study-item">
          <a
            href="https://www.figma.com/proto/TwZ4w5VoPtoHsOeDQIrafX/Case-studies?node-id=22-212&scaling=min-zoom&page-id=1%3A170&starting-point-node-id=22%3A212"
            target="_blank"
          >
            <img src={caseStudy4} className="case-study" alt="menu" />
          </a>
        </div>
      </div>
    </div>
  );
};

const CaseStudiesMobile = () => {
  const handleSelect = (selectedIndex, e) => {
    // do nothing for now
  };

  return (
    <div>
      <div className="row mobile">
        <div className="case-study-items-mobile">
          <Carousel onSelect={handleSelect}>
            <Carousel.Item>
              <div className="case-study-item-mobile">
                <a
                  href="https://www.figma.com/proto/TwZ4w5VoPtoHsOeDQIrafX/Case-studies?node-id=1-139&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=1%3A139"
                  target="_blank"
                >
                  <img src={caseStudy1} className="case-study" alt="menu" />
                </a>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="case-study-item-mobile">
                <a
                  href="https://www.figma.com/proto/TwZ4w5VoPtoHsOeDQIrafX/Case-studies?node-id=1-275&scaling=min-zoom&page-id=1%3A168&starting-point-node-id=1%3A275"
                  target="_blank"
                >
                  <img src={caseStudy2} className="case-study" alt="menu" />
                </a>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="case-study-item-mobile">
                <a
                  href="https://www.figma.com/proto/TwZ4w5VoPtoHsOeDQIrafX/Case-studies?node-id=1-409&scaling=min-zoom&page-id=1%3A169&starting-point-node-id=1%3A409"
                  target="_blank"
                >
                  <img src={caseStudy3} className="case-study" alt="menu" />
                </a>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="case-study-item-mobile">
                <a
                  href="https://www.figma.com/proto/TwZ4w5VoPtoHsOeDQIrafX/Case-studies?node-id=1-525&scaling=min-zoom&page-id=1%3A170&starting-point-node-id=1%3A525"
                  target="_blank"
                >
                  <img src={caseStudy4} className="case-study" alt="menu" />
                </a>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
};

// Featured projects

const FeaturedProjects = () => {
  return (
    <div id="our-work" className="featured-projects container">
      <div className="row section">
        <p className="center">Featured Projects</p>
      </div>

      <div className="row featured-projects-description">
        <div className="col-lg-3"></div>
        <div className="col-lg-6">
          <p className="center">
            Explore our range of award-winning web designs, apps, logos, and
            branding that will inspire your creativity. Discover why our design
            team stands out as one of the best in the industry.
          </p>
        </div>
      </div>

      <CaseStudiesDesktop />
      <CaseStudiesMobile />

      <div className="row featured-projects-action">
        <div className="featured-action-container">
          <YellowButton
            title={"View our work"}
            link={
              "https://www.figma.com/proto/TwZ4w5VoPtoHsOeDQIrafX/Case-studies?node-id=1-139&scaling=min-zoom&page-id=0%3A1&starting-point-node-id=1%3A139"
            }
            className="mt-32"
          />
        </div>
      </div>
    </div>
  );
};

// Services

const Services = () => {
  return (
    <div className="services-container">
      <div className="container white-text">
        <div className="row">
          <div className="col-lg-6">
            <div className="mb-40">
              <img src={flowerImage} className="flower-image" alt="menu" />
            </div>

            <div className="section mb-40">
              Ready to start?<br></br> Let’s get to work!
            </div>

            <div className="services-description">
              Our skilled designers create exceptional designs that elevate your
              brand and captivate your audience. We work efficiently to deliver
              high-quality designs that respect your time. Let's bring your
              design vision to life.
            </div>

            <div className="">
              <YellowButton
                title={"Try for Free"}
                link={"/subscribe?option=monthly"}
                className="mt-32"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="mobile mt-40"></div>
            <img src={servicesImage} className="services-image" alt="menu" />
          </div>
        </div>
      </div>
    </div>
  );
};

// Membership

const MembershipItem = ({
  title,
  subtitle,
  items,
  price,
  link,
  first = false,
  type,
  freeTrial = false,
}) => {
  let itemsList = [];

  items.map((item) => {
    itemsList.push(<div className="membership-perk">{item}</div>);
  });

  let item = <div className="membership-item-title">{title}</div>;
  if (first) {
    item = <div className="membership-item-title-first">{title}</div>;
  }

  let freeTrialItem;
  let buttonTitle = "Get started";
  if (freeTrial) {
    freeTrialItem = (
      <div className="membership-item-free-trial">
        <span className="bold-text">7 days-free trial.</span>
      </div>
    );

    buttonTitle = "Try for Free";
  }

  return (
    <div className="membership-item flex-column">
      {item}
      <div className="membership-item-subtitle">{subtitle}</div>
      {freeTrialItem}
      <div className="membership-item-whats-included">What's included?</div>
      <div className="membership-perks-list">{itemsList}</div>

      <div className="membership-item-bottom position-relative">
        <p className="membership-item-price-label">{price}</p>
        <YellowSmallButtonAction
          title={buttonTitle}
          action={() => {
            navigateToSubscribeWithOption(type);
          }}
        />
      </div>
    </div>
  );
};

const MembershipItemMobile = ({
  title,
  subtitle,
  items,
  price,
  link,
  type,
  first = false,
  freeTrial = false,
}) => {
  let itemsList = [];

  items.map((item) => {
    itemsList.push(<div className="membership-perk-mobile">{item}</div>);
  });

  let item = <div className="membership-item-title-mobile">{title}</div>;
  if (first) {
    item = <div className="membership-item-title-first-mobile">{title}</div>;
  }

  let freeTrialItem;
  let buttonTitle = "Get started";
  if (freeTrial) {
    freeTrialItem = (
      <div className="membership-item-free-trial-mobile">
        <span className="bold-text">7 days-free trial.</span>
      </div>
    );

    buttonTitle = "Try for Free";
  }

  return (
    <div className="membership-item-mobile flex-column">
      {item}
      <div className="membership-item-subtitle-mobile">{subtitle}</div>
      {freeTrialItem}
      <div className="membership-item-whats-included-mobile">
        What's included?
      </div>
      <div className="membership-perks-list">{itemsList}</div>

      <div className="membership-item-bottom position-relative">
        <p className="membership-item-price-label">{price}</p>
        <YellowSmallButtonAction
          title={buttonTitle}
          action={() => {
            navigateToSubscribeWithOption(type);
          }}
        />
      </div>
    </div>
  );
};

const MembershipCallItem = () => {
  return (
    <div className="membership-item no-border flex-column">
      <div>
        <div className="">
          <img src={freeTrial} className="book-a-call-image" alt="menu" />
        </div>
        <div className="membership-item-title book-a-call-title-free-trial">
          Free trial
        </div>
        <div className="membership-item-subsubtitle">
          Try for free for <span className="bold-text">1 week</span> with our
          monthly subscription.
        </div>
        <BorderedButton
          title={"Try it Free"}
          link={"/subscribe?option=monthly"}
          className="mt-20"
        />
      </div>
      <div>
        <div className="">
          <img src={bookACallImage} className="book-a-call-image" alt="menu" />
        </div>
        <div className="membership-item-title book-a-call-title">
          Book a call
        </div>
        <div className="membership-item-subsubtitle">
          Discover how Designesy works and how it can benefit you.
        </div>
        <BorderedButton
          title={"Book a call"}
          link={"https://calendly.com/dragos-appssemble/project-discussion"}
          className="mt-20"
        />
      </div>
    </div>
  );
};

const MembershipCallItemMobile = () => {
  return (
    <div>
      {" "}
      <div className="membership-item-call-mobile no-border flex-column">
        <div className="">
          <img
            src={freeTrial}
            className="book-a-call-image-mobile"
            alt="menu"
          />
        </div>
        <div className="membership-item-title book-a-call-title">
          Free trial
        </div>
        <div className="membership-item-subtitle-mobile-call">
          Try for free for <span className="bold-text">1 week</span>
          <br></br> with our monthly subscription.
        </div>
        <BorderedButton title={"Try for free"} link={"#"} className="mt-40" />
      </div>
      <div className="membership-item-call-mobile no-border flex-column mt-60">
        <div className="">
          <img
            src={bookACallImage}
            className="book-a-call-image-mobile"
            alt="menu"
          />
        </div>
        <div className="membership-item-title book-a-call-title">
          Book a call
        </div>
        <div className="membership-item-subtitle-mobile-call">
          Discover how Designesy works and how it can<br></br> benefit you.
        </div>
        <BorderedButton title={"Book a call"} link={"#"} className="mt-40" />
      </div>
    </div>
  );
};

const MembershipItemsDesktop = () => {
  return (
    <div className="row membership-options desktop">
      <div className="col-3">
        <img
          src={mostPopularImage}
          className="most-popular-service-image"
          alt="menu"
        />
        <MembershipItem
          title={"Monthly"}
          subtitle={"Pause or cancel anytime."}
          items={[
            "Unlimited requests",
            "Unlimited brands",
            "Unlimited users",
            "",
          ]}
          price={"$4,495/m"}
          link="#"
          first={true}
          freeTrial={true}
          type="monthly"
        />
      </div>
      <div className="col-3">
        <MembershipItem
          title={"Quarterly"}
          subtitle={"Save $500 per month."}
          items={[
            "Unlimited requests",
            "Unlimited brands",
            "Unlimited users",
            "",
          ]}
          price={"$3,995/m"}
          link="#"
          type="quarterly"
        />
      </div>
      <div className="col-3">
        <MembershipItem
          title={"Yearly"}
          subtitle={"Save $1000 per month."}
          items={[
            "Unlimited requests",
            "Unlimited brands",
            "Unlimited users",
            "",
          ]}
          price={"$3,495/m"}
          link="#"
          type="yearly"
        />
      </div>
      <div className="col-3">
        <MembershipCallItem />
      </div>
    </div>
  );
};

const MembershipItemsMobile = () => {
  const handleSelect = (selectedIndex, e) => {
    if (selectedIndex == 0) {
      $("#most-popular-mobile").show();
    } else {
      $("#most-popular-mobile").hide();
    }
  };

  return (
    <div>
      <div className="row mobile membership-row-mobile">
        <div className="membership-options">
          <img
            id="most-popular-mobile"
            src={mostPopularImage}
            className="most-popular-service-image-mobile"
            alt="menu"
          />
          <Carousel onSelect={handleSelect}>
            <Carousel.Item>
              <MembershipItemMobile
                title={"Monthly"}
                subtitle={"Pause or cancel anytime."}
                items={[
                  "Unlimited requests",
                  "Unlimited brands",
                  "Unlimited users",
                  "",
                ]}
                price={"$4,495/m"}
                link="#"
                type={"monthly"}
                freeTrial={true}
                first={true}
              />
            </Carousel.Item>
            <Carousel.Item>
              <MembershipItemMobile
                title={"Quarterly"}
                subtitle={"Save $1000 per month."}
                items={[
                  "Unlimited requests",
                  "Unlimited brands",
                  "Unlimited users",
                  "",
                ]}
                price={"$3,995/m"}
                link="#"
                type={"quarterly"}
              />
            </Carousel.Item>
            <Carousel.Item>
              <MembershipItemMobile
                title={"Yearly"}
                subtitle={"Save $1000 per month."}
                items={[
                  "Unlimited requests",
                  "Unlimited brands",
                  "Unlimited users",
                  "",
                ]}
                price={"$3,595/m"}
                link="#"
                type={"yearly"}
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
      <div className="row mobile call-mobile-row membership-row-mobile">
        <MembershipCallItemMobile />
      </div>
    </div>
  );
};

const Membership = () => {
  return (
    <div className="membership-container" id="plans">
      <div className="container">
        <div className="row">
          <div className="center section membership-title">Membership</div>
        </div>

        <MembershipItemsDesktop />
        <MembershipItemsMobile />
      </div>
    </div>
  );
};

// FAQs

const faqs = [
  {
    title: "Why wouldn't I just hire a full-time designer?",
    description: `
    There are several reasons why you might not want to hire a full-time designer:<br></br>
    
    1. Cost: Hiring a full-time designer can be expensive, especially if you are a small business or just starting out. You would have to pay them a salary, benefits, and possibly other costs such as equipment and software.<br></br>

    2. Limited expertise: A full-time designer might have expertise in a specific area of design, but they might not have the broad range of skills needed to handle all your design needs. Hiring our team of designers can give you access to a wider range of expertise and experience.<br></br>

    3. Flexibility: Hiring a full-time designer means committing to a certain number of hours per week or month. If you don't have enough design work to keep them busy, you're paying for their downtime. On the other hand, by using Designesy you have the flexibility to use our services only when you need them.<br><br>
    
    4. Access to the latest trends and technology: Design is constantly evolving, and it can be difficult for a full-time designer to stay up-to-date with the latest trends and technology. By working with our team of designers, you can benefit from their collective knowledge and expertise.<br><br>

    5. Limited availability: A full-time designer may not be available to work on your project at a particular time due to sick leave, vacation, or other reasons. In contrast, working with our team of designers can ensure that you have access to design resources when you need them.<br><br>

    <b>In summary, working with our team of designers can provide you with greater flexibility, expertise, cost savings, and reduced management responsibilities than hiring a full-time designer.</b>
    `,
  },
  {
    title: "Is there a limit to how many requests I can have?",
    description:
      "Once you become a member, you're able to add as many design requests to your queue as you'd like, and they will be delivered one by one. You can reorder the items based on the delivery priority.",
  },
  {
    title: "How fast will I receive my designs?",
    description:
      "Typically, design requests are fulfilled within a short turnaround time of one-two days or less. However, more complex design projects may require additional time to complete. <br></br> You can view the status of your designs in real-time in your tasks board.",
  },
  {
    title: "Who are the designers?",
    description: `
    We are a team of talented and experienced designers who are dedicated to providing you with high-quality design services for all your business needs.<br></br>

    At Designesy, we understand the importance of creating designs that not only look great but are also valid for implementation. That's why our designers have extensive experience working with development teams, giving us the ability to produce designs that are not only visually appealing but also functional and easy to implement.<br></br>

    One of the unique aspects of our team is that all of our designers are hired in-house, which means that you can rest assured that your design project will receive our full attention and dedication. We do not outsource our work, so you can be confident that the designs we produce for you are created by our team of experienced designers who are passionate about design and committed to delivering the best possible results for our clients.
    `,
  },
  {
    title: "What programs do you design in?",
    description: `
    Our design team utilizes a wide range of tools to ensure that we provide the highest quality design services possible. We are proficient in several industry-standard design software including Figma, Photoshop, and Illustrator, all of which are essential in creating stunning and effective designs.<br></br>

    Figma is a cloud-based design tool that allows our team to collaborate seamlessly on projects, making it an ideal tool for team collaboration. With Figma, we can work with our clients in real-time, receive feedback, and make revisions, all in one place.<br></br>
    
    Photoshop and Illustrator are other popular design tools that we use to create pixel-perfect designs for web, print, and other mediums. We utilize these tools to create logos, brochures, flyers, and other design projects.<br></br>
    
    We also use Overflow, a user flow diagramming tool, to create interactive prototypes and visualize user flows for web and mobile applications. Overflow is an excellent tool for receiving feedback and making revisions, which helps us to refine and improve our designs.<br></br>
    `,
  },
  {
    title: "How do I request designs?",
    description: `
    We use a task board to manage and track our design projects effectively. Our task board is a central location where we organize and prioritize design tasks and keep track of their progress.<br></br>

    A task is represented by a card on the board. In that card, you can attach links to your documents, write the requirement, and paste videos, images, and even Loom videos, so that our designers can understand better your vision.<br></br>

    In short, you can use any sort of digital asset (documents, images, and videos).<br></br>

    Our designers will begin to work on the cards at the top of the queue, once a task is finished (reviewed and approved), the work on the next one will start.<br></br>

    You can change the order of the cards at any point, to change their priorities.
    `,
  },
  {
    title: "What if I don't like the design?",
    description: `
    At Designesy, we take great pride in delivering high-quality designs that exceed our clients' expectations. However, we understand that design is subjective, and what may appeal to one person may not necessarily appeal to another.<br></br>

    That's why we offer a satisfaction guarantee on our design services. If you are not satisfied with the design, we will work with you to make it right. Our design process includes receiving feedback from our clients, and we encourage them to add as many comments as they want in Overflow, which is a tool that we use for feedback and revisions.<br></br>

    If you don't like the design, we will listen to your feedback and make the necessary revisions. We will continue to recreate the design until you are satisfied with the final result. 
    `,
  },
  {
    title: "What if I only have a single request?",
    description:
      "That's not a problem. If you have a single design request, you can pause your subscription when you're finished, and return to it when you have additional design needs. We want to make sure you get the most value from your subscription, so there's no need to let the remainder of your subscription go to waste.",
  },
  {
    title: "Are there any refunds if I don't like the service?",
    description: `
    Unfortunately, we do not offer refunds for our design services. <br></br>
    
    There are several reasons for this policy:<br></br>

    1. Firstly, our team invests significant time and effort into each design request we receive. We work hard to ensure that our designs meet our clients' expectations and align with their brand's vision. Refunds would not adequately compensate us for the time and effort that goes into creating a design.<br></br>

    2. Secondly, design is subjective, and what may appeal to one person may not necessarily appeal to another. Our design process includes receiving feedback from our clients and making revisions until they are satisfied with the final result. We are committed to working with our clients until they are happy with the final design, and we believe that our satisfaction guarantee policy offers a fair solution for any concerns they may have.<br></br>

    3. Lastly, our subscription-based service provides flexibility and convenience for our clients, as they can add design requests to their queue and have them delivered on a schedule that suits them. Refunds would not be compatible with this model and could disrupt the balance of our design process.<br></br>

    We believe that our no-refund policy is fair and reasonable, as we are committed to providing high-quality design services that exceed our clients' expectations.
    `,
  },
];

const Faqs = () => {
  return (
    <div className="faq-container" id="faqs">
      <div className="container">
        <div className="row mt-100">
          <div className="col">
            <h1 className="section center mb-80">FAQs</h1>
            {faqs.map((item, index) => (
              <FaqQuestion
                key={index}
                title={item.title}
                description={item.description}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const HomePage = ({ setLoading }) => {
  return (
    <div id="home">
      <Header />
      <Process />
      <Perks />
      <Benefits />
      <FeaturedProjects />
      <Services />
      <Membership />

      <Faqs />
      <TrackingComponent />
    </div>
  );
};

export default HomePage;
