import AGuideForFoundersBlogPost from "./content/AGuideForFoundersBlogPost";
import DesigningAMobileApp from "./content/DesigningAMobileApp";
import DesigningEffectiveSocialMediaGraphics from "./content/DesigningEffectiveSocialMediaGraphics";
import SubscriptionBasedDesignServices from "./content/SubscriptionBasedDesignServices";

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export const blogPostsArray = () => {
  const returnArray = [
    AGuideForFoundersBlogPost,
    DesigningEffectiveSocialMediaGraphics,
    DesigningAMobileApp,
    SubscriptionBasedDesignServices,
  ];

  return shuffle(returnArray);
};

export const generateTwoRandomBlogPostsFor = (post) => {
  let returnArray = [];
  const blogPostArrays = blogPostsArray();

  blogPostArrays.forEach((element) => {
    if (element.href !== post.href) {
      returnArray.push(element);
    }

    if (returnArray.length === 2) {
      return returnArray;
    }
  });

  return returnArray;
};
