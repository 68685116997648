import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Navigation from "./components/header/Navigation";
import Footer from "./components/footer/Footer";
import LoginPage from "./pages/LoginPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import NewPasswordPage from "./pages/NewPasswordPage";
import SubscribePage from "./pages/SubscribePage";
import DashboardPage from "./pages/DashboardPage";
import UserContextProvider from "./components/model/context/UserContext";
import EditUserPage from "./pages/EditUserPage";
import TermsAndConditionsPage from "./pages/TermsAndConditionsPage";
import { SpinnerComponent } from "./components/generic/SpinnerComponent";
import { useState } from "react";
import BlogPage from "./pages/BlogPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import CookieConsent from "./components/cookies/CookieConsent";
import BlogPostPage from "./pages/BlogPostPage";
import { blogPostsArray } from "./components/blog-posts/BlogPostsBuilder";

import $ from "jquery";

function App() {
  let isMainPage =
    window.location.pathname === "/" ||
    window.location.pathname.startsWith("/blog") ||
    window.location.pathname === "/terms-and-conditions" ||
    window.location.pathname === "/privacy-policy";
  let isDashboardPage =
    window.location.pathname === "/dashboard" ||
    window.location.pathname === "/edit";

  const [loading, setLoading] = useState(false);

  const generateBlogPosts = (setLoading) => {
    let routes = [];

    blogPostsArray().forEach((element) => {
      routes.push(
        <Route
          path={element().href}
          element={
            <BlogPostPage setLoading={setLoading} blogPost={element()} />
          }
        />
      );
    });

    return routes;
  };

  const components = (
    <UserContextProvider setLoading={setLoading}>
      <div className="App">
        <div id="app-content">
          <Navigation showMenu={isMainPage} showLogout={isDashboardPage} />
          <div id="menu-spacer"> </div>
          <Routes>
            <Route index element={<HomePage setLoading={setLoading} />} />
            <Route path="/" element={<HomePage setLoading={setLoading} />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route
              path="/login"
              element={<LoginPage setLoading={setLoading} />}
            />
            <Route
              path="/reset-password"
              element={<ResetPasswordPage setLoading={setLoading} />}
            />
            <Route
              path="/new-password"
              element={<NewPasswordPage setLoading={setLoading} />}
            />
            <Route
              path="/subscribe"
              element={<SubscribePage setLoading={setLoading} />}
            />
            <Route
              path="/dashboard"
              element={<DashboardPage setLoading={setLoading} />}
            />
            <Route
              path="/edit"
              element={<EditUserPage setLoading={setLoading} />}
            />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditionsPage setLoading={setLoading} />}
            />
            <Route
              path="/privacy-policy"
              element={<PrivacyPolicyPage setLoading={setLoading} />}
            />
            <Route
              path="/blog-post"
              element={<BlogPostPage setLoading={setLoading} />}
            />
            {/* Blog posts routes */}
            {generateBlogPosts(setLoading)}
          </Routes>
          {isMainPage && <Footer />}
        </div>
      </div>
    </UserContextProvider>
  );

  return (
    <BrowserRouter>
      <SpinnerComponent
        loading={loading}
        components={components}
      ></SpinnerComponent>
      <CookieConsent />
    </BrowserRouter>
  );
}

export default App;
