import Cookies from "universal-cookie";

export const navigateToResetPassword = () => {
  window.location.href = "/reset-password";
};

export const navigateToSubscribe = () => {
  window.location.href = "/subscribe";
};

export const navigateToSubscribeWithOption = (option) => {
  window.location.href = "/subscribe?option=" + option;
};

export const navigateToDashboard = (redirectTo = null) => {
  const url = window.location.origin + "/dashboard";
  window.location.replace(url);
};

export const navigateToDashboardWithCard = (cardID) => {
  const url = window.location.origin + "/dashboard?cardId=" + cardID;
  window.location.replace(url);
};

export const navigateToLogin = (redirectTo = null) => {
  let finalPath = "/login";

  if (redirectTo) {
    finalPath = finalPath + "/action" + redirectTo;
  }

  window.location.href = finalPath;
};

export const navigateToURL = (url) => {
  window.location.href = url;
};

export const navigateToEditProfile = () => {
  window.location.href = "/edit";
};

export const navigateToTasks = (url) => {
  navigateToURL(url);
};
