import $ from "jquery";
import image from "../resources/img/login.svg";
import { useState, useEffect } from "react";

import { YellowButtonAction } from "../components/generic/YellowButtonAction";
import { InputField } from "../components/generic/InputField";
import { SimpleButton } from "../components/generic/SimpleButton";
import { ErrorLabel } from "../components/generic/ErrorLabel";
import UserContextProvider, {
  useUserContext,
} from "../components/model/context/UserContext";

import editHeaderImage from "../resources/img/edit-header.svg";
import TrackingComponent from "../components/tracking/TrackingComponent";

import {
  navigateToDashboard,
  navigateToResetPassword,
  navigateToSubscribe,
} from "../helpers/NavigationHelpers";

import { isEmailValid } from "../helpers/ValidatorHelper";

const EditUserPage = ({ setLoading }) => {
  const { currentUser, loginUser, navigateToDashboardIfPossible, editUser } =
    useUserContext();
  const [loginEnabled, setLoginEnabled] = useState(false);
  const [showError, setShowError] = useState(false);

  const name = currentUser().name;
  const email = currentUser().email;

  const editAction = () => {
    setLoading(true);
    const emailValue = $("#email").val();
    const nameValue = $("#name").val();

    setLoading(true);
    editUser(
      nameValue,
      emailValue,
      (response) => {
        setLoading(false);
        navigateToDashboardIfPossible();
      },
      () => {
        setLoading(false);
      }
    );
  };

  const enableLoginButtonIfNeeded = () => {
    const emailValue = $("#email").val();
    const nameValue = $("#name").val();

    const emailValid = emailValue.length > 0 && isEmailValid(emailValue);
    const nameValid = nameValue.length > 2;

    const emailChanged = emailValue !== email;
    const nameChanged = nameValue !== name;

    setShowError(false);
    if (
      emailValid === true &&
      nameValid === true &&
      (emailChanged === true || nameChanged == true)
    ) {
      setLoginEnabled(true);
    } else {
      setShowError(true);
      setLoginEnabled(false);
    }
  };

  let errorContainer = (
    <div className="col error-message">
      <ErrorLabel text={"Wrong email or password"} />
    </div>
  );

  if (showError === false) {
    errorContainer = <div></div>;
  }

  useEffect(() => {
    enableLoginButtonIfNeeded();
  }, []);

  return (
    <div id="edit-user" className="">
      <div className="container">
        <div className="row">
          <div className="col d-flex justify-content-center">
            <div className="edit-user-container">
              <img
                src={editHeaderImage}
                className="edit-header-image"
                alt="menu"
              />
              <div className="edit-fields-container">
                <div className="edit-title-container mb-40">Edit Profile</div>
                <InputField
                  placeholder={"Name"}
                  id={"name"}
                  type={"name"}
                  onChange={enableLoginButtonIfNeeded}
                  value={name}
                />
                <InputField
                  placeholder={"Email"}
                  id={"email"}
                  type={"email"}
                  onChange={enableLoginButtonIfNeeded}
                  value={email}
                />
                <div className="edit-action-container mt-60">
                  <YellowButtonAction
                    title={"Save"}
                    action={editAction}
                    active={loginEnabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TrackingComponent />
    </div>
  );
};

export default EditUserPage;
