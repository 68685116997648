export const YellowButton = ({ title, link, className = null }) => {
  return (
    <div className={`yellow-button-container ${className}`}>
      <div className="yellow-button">
        <a href={link} target="_blank">
          {title}
        </a>
      </div>
      <div className="yellow-button-background"></div>
    </div>
  );
};
