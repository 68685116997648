import starsImage from "../../resources/img/stars.svg";
import logoImage from "../../resources/img/logo.svg";

import instagramImage from "../../resources/img/instagram.svg";
import dribbbleImage from "../../resources/img/dribbble.svg";
import behanceImage from "../../resources/img/behance.svg";
import linkedinImage from "../../resources/img/linkedin.svg";

function FooterLink({ className = null, img, href, text }) {
  if (img != null) {
    return (
      <div className={"footer-link-container  " + (className ? className : "")}>
        <a className="footer-link-href" href={href}>
          <img src={img} className="footer-icon" alt="social-icon" />
          <span className="footer-link-text">{text}</span>
        </a>
      </div>
    );
  }

  return (
    <div className={"footer-link-container " + (className ? className : "")}>
      <a className="footer-link-href" href={href}>
        <span className="footer-link-text">{text}</span>
      </a>
    </div>
  );
}

function FooterDesktop() {
  return (
    <div className="container footer-items desktop">
      <div className="row">
        <div className="col-lg-4">
          <div className="logo-container">
            <a href="/">
              <img src={logoImage} className="logo-small d-flex" alt="logo" />
            </a>
          </div>
          <div className="footer-disc">
            Copyright © {new Date().getFullYear()}{" "}
            <span className="footer-bold">desingesy</span>. All rights reserved.
          </div>
        </div>
        <div className="col-lg-4"></div>
        <div className="col-lg-2">
          <span className="footer-section">Social</span>
          <FooterLink
            img={instagramImage}
            href={"https://www.instagram.com/designesy_digital/"}
            text={"Instagram"}
          />
          <FooterLink
            img={dribbbleImage}
            href={"https://dribbble.com/designesy_digital"}
            text={"Dribbble"}
          />
          <FooterLink
            img={behanceImage}
            href={
              "https://www.behance.net/designedigital1?tracking_source=search_users%7Cdesignesy%20digital"
            }
            text={"Behance"}
          />
          <FooterLink
            img={linkedinImage}
            href={
              "https://www.linkedin.com/company/designesy-digital/about/?viewAsMember=true"
            }
            text={"LinkedIn"}
          />
        </div>
        <div className="col-lg-2">
          <span className="footer-section">Support</span>
          <FooterLink
            href={"/terms-and-conditions"}
            text={"Terms & Conditions"}
          />
          <FooterLink href={"/privacy-policy"} text={"Privacy Policy"} />
          <FooterLink href={"mailto:office@designesy.com"} text={"Contact"} />
        </div>
      </div>
    </div>
  );
}

function FooterMobile() {
  return (
    <div className="container footer-items mobile">
      <div className="row">
        <div className="col-lg-12">
          <div className="logo-container">
            <a href="/">
              <img src={logoImage} className="logo-small d-flex" alt="logo" />
            </a>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-6">
          <span className="footer-section">Social</span>
          <FooterLink
            img={instagramImage}
            href={"https://www.instagram.com/designesy_digital/"}
            text={"Instagram"}
          />
          <FooterLink
            img={dribbbleImage}
            href={"https://dribbble.com/designesy_digital"}
            text={"Dribbble"}
          />
          <FooterLink
            img={behanceImage}
            href={
              "https://www.behance.net/designedigital1?tracking_source=search_users%7Cdesignesy%20digital"
            }
            text={"Behance"}
          />
          <FooterLink
            img={linkedinImage}
            href={
              "https://www.linkedin.com/company/designesy-digital/about/?viewAsMember=true"
            }
            text={"LinkedIn"}
          />
        </div>
        <div className="col-6">
          <span className="footer-section">Support</span>
          <FooterLink href={"/privacy-policy"} text={"Privacy policy"} />
          <FooterLink href={"mailto:office@designesy.com"} text={"Contact"} />
        </div>
      </div>
      <div className="row mt-5">
        <div className="footer-disc">
          Copyright © {new Date().getFullYear()}{" "}
          <span className="footer-bold">desingesy</span>. All rights reserved.
        </div>
      </div>
    </div>
  );
}

export default function Footer() {
  return (
    <div className="footer-container white-text">
      <FooterDesktop />
      <FooterMobile />
    </div>
  );
}
