import selectorImageEmpty from "../resources/img/membership-selector-empty.svg";
import selectorImageFilled from "../resources/img/membership-selector-filled.svg";
import xDaysFreeTrial from "../resources/img/7-days-free-trial.svg";

const MembershipOption = ({
  title,
  subtitle,
  price,
  selected = true,
  action,
  option,
  customClass = null,
  isFreeTrial = false,
}) => {
  let selectorImage = selectorImageEmpty;
  let containerClassNames = "membership-option";

  if (customClass) {
    containerClassNames += " " + customClass;
  }

  if (selected) {
    selectorImage = selectorImageFilled;
    containerClassNames += " membership-option-border";
  }

  let freeTrialComponent = (
    <div className="membership-free-trial-container">
      <img src={xDaysFreeTrial} className="membership-days-free-trial" />
    </div>
  );

  if (!isFreeTrial) {
    freeTrialComponent = "";
  }

  return (
    <div className={containerClassNames} onClick={() => action(option)}>
      {freeTrialComponent}
      <div className="container full-height">
        <div className="row full-height">
          <div className="col membership-selector d-flex align-items-center justify-content-left">
            <img src={selectorImage} className="membership-selector" />
          </div>
          <div className="col membership-text d-flex align-items-start flex-column">
            <div className="membership-plan-title">{title}</div>
            <div className="membership-plan-subtitle">{subtitle}</div>
          </div>
          <div className="col membership-price">{price}</div>
        </div>
      </div>
    </div>
  );
};

export default MembershipOption;
