import $ from "jquery";

import blogPostImage from "../resources/img/blog-image.svg";
import TrackingComponent from "../components/tracking/TrackingComponent";
import parse from "html-react-parser";
import BlogPostItem from "../components/generic/BlogPostItem";
import { generateTwoRandomBlogPostsFor } from "../components/blog-posts/BlogPostsBuilder";

import startFreeTrialImage from "../resources/img/start-free-trial-blog.svg";
import startFreeTrialImageMobile from "../resources/img/start-free-trial-blog-mobile.svg";

const BlogPostPage = ({ setLoading, blogPost }) => {
  const otherBlogPosts = generateTwoRandomBlogPostsFor(blogPost);
  const otherBlogPost1 = otherBlogPosts[0]();
  const otherBlogPost2 = otherBlogPosts[1]();

  return (
    <div className="blog-page white">
      <div className="container mb-60">
        <div className="row">
          <div className="col-lg-12">
            <div className="blog-post-image-container">
              <img
                src={blogPost.headerImage}
                className="blog-page-post-image"
                alt="blog-post"
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div className="blog-page-title">{blogPost.title}</div>
            <div className="blog-page-reading-time">
              {blogPost.readTime} mins. reading time
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 blog-page-text">{blogPost.content}</div>
        </div>

        <div className="row">
          <div className="col">
            <a href="/subscribe?option=monthly">
              <div className="desktop blog-free-trial-image-container mt-60">
                <img
                  src={startFreeTrialImage}
                  className="full-width"
                  alt="start free trial"
                />
              </div>

              <div className="mobile blog-free-trial-image-container">
                <img
                  src={startFreeTrialImageMobile}
                  className="full-width"
                  alt="start free trial"
                />
              </div>
            </a>
          </div>
        </div>

        <div className="row">
          <div className="blog-page-read-more-articles">
            Read more articles from our blog
          </div>
          <div className="col-lg-5">
            <BlogPostItem blogPost={otherBlogPost1} noDescription={true} />
          </div>

          <div className="col-lg-2"></div>

          <div className="col-lg-5">
            <BlogPostItem blogPost={otherBlogPost2} noDescription={true} />
          </div>
        </div>
      </div>

      <div className="pb-60"></div>
      <TrackingComponent />
    </div>
  );
};

export default BlogPostPage;
